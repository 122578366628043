import {isBetaDomain, isProdEnvironment} from 'App';
import moment from 'moment';
import store from 'store';
import {trackEventsService} from 'store/services/eventsServices';
import {BaseEventData, EventData, EventName} from './types';

interface UseTrackEventReturn {
  trackEvent(eventName: EventName, payload?: EventData): void;
}

function replaceNullishValues(key, value) {
  // Converting null and NaN to undefined, which will be later removed from the payload by JSON.stringify()
  if (value === null || Number.isNaN(value)) {
    return undefined;
  }
  return value;
}

function useTrackEvent(): UseTrackEventReturn {
  const state = store.getState();
  const user = state.getIn(['user', 'data']);

  function getEventPayload(eventName: EventName, payload?: EventData) {
    switch (eventName) {
      case 'teacher_login':
      case 'click_on_test_type_tab':
      case 'test_page_access':
      case 'pre_writing_themes_access':
      case 'pre-writing_material_tab_click':
      case 'enem_BNCC_skills_button_click':
      case 'enem_reopen_test_button_click':
      case 'enem_grammatical_aspects_button_click':
      case 'students_page_views':
      case 'reset_password_click':
      case 'update_student_profile_click':
      case 'themes_page_access':
      case 'correction_grids_page_access':
      case 'evolution_report_page_access':
      case 'knowledge_center_page_access':
      case 'regular_learning_path_card_click':
      case 'sidebar_click_learning_path':
        return {};

      case 'type_of_test_access':
      case 'transition_between_school_group_and_students_tabs':
      case 'transition_between_competences_and_indicators_tabs':
      case 'enem_test_access':
      case 'enem_test_reading_button_click':
        return {...payload?.test};

      case 'reviewed_composition_access':
        return {...payload?.test, ...payload?.composition};

      case 'multigenre_report_access':
      case 'multigenre_reading_button_click':
      case 'multigenre_report_step_click':
      case 'multigenre_access_student_list_button_click':
      case 'multigenre_access_engagement_button_click':
      case 'enem_learning_path_access':
      case 'enem_reading_button_click':
      case 'enem_learning_path_step_click':
      case 'BNCC_skills_button_click':
      case 'reading_indicators_report_next_slide_click':
      case 'reading_indicators_report_previous_slide_click':
      case 'reopen_test_button_click':
      case 'reading_indicators_question_click':
      case 'learning_path_report_access':
      case 'learning_path_report_step_click':
      case 'learning_path_access_student_list_button_click':
      case 'learning_path_access_engagement_button_click':
      case 'learning_path_BNCC_skills_button_click':
        return {...payload?.learningPathInstance};

      case 'worst_competence_mediation_popup_click':
      case 'worst_competence_mediation_download_click':
        return {...payload?.learningPathInstance, ...payload?.test};

      case 'worst_competence_mediation_share_click':
        return {
          ...payload?.learningPathInstance,
          ...payload?.test,
          ...payload?.supportingContent,
        };

      case 'composition_review_edited':
        return {...payload?.review};

      case 'multigenre_competence_next_steps_click':
        return {...payload?.learningPathInstance, ...payload?.review};

      case 'in_progress_composition_access':
      case 'learning_path_in_progress_composition_access':
        return {...payload?.learningPathInstance, ...payload?.composition};

      default:
        return {};
    }
  }

  function trackEvent(eventName: EventName, payload?: EventData) {
    const baseEventPayload: BaseEventData = {
      event_name: eventName,
      user_id: user?.get('id'),
      event_date: moment().format('YYYYMMDD'),
      event_time: moment().format('HHmmss'),
      user_system_info: navigator.userAgent,
      user_screen_resolution: `${window.screen.height}x${window.screen.width}`,
    };

    const requestPayload = JSON.stringify(
      {
        ...baseEventPayload,
        ...getEventPayload(eventName, payload),
      },
      replaceNullishValues,
    );

    if (
      (isProdEnvironment && !isBetaDomain) ||
      process.env.REACT_APP_IS_DEV_EVENTS_ENABLED === 'true'
    ) {
      trackEventsService({
        DeliveryStreamName: isProdEnvironment
          ? 'clickstream-prod-stream'
          : 'clickstream-dev-stream',
        Record: {
          Data: window.btoa(requestPayload),
        },
      });
    }
  }

  return {
    trackEvent,
  };
}

export default useTrackEvent;
